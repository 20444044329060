$(document).foundation({
    equalizer: {
        // Specify if Equalizer should make elements equal height once they become stacked.
        equalize_on_stack: true
    }
})
$(document).ready(function () {
    if ($('.bgimage img').length > 0) {
        setBgImage();
    }
})

$(document).ajaxStop(function () {
    workGroupMemberList();
    if ($('#lbl_usernameretrievepassword').length > 0) {
        $("#lbl_usernameretrievepassword").text('Vul uw gebruikersnaam of e-mailadres in. U ontvangt de login informatie per e-mail.')
    }
})

//Functies docready
var setBgImage = function () {
    $('.bgimage').each(function () {
        var src = $(this).find('img').attr('src');
        if (src) {
            $(this).css('background-image', 'url("' + src + '")');
        }
    });
};

// AjaxStop
//Werkgroep memberlist inkorten
function workGroupMemberList() {

    var element = $("#workgroupMembersList");
    var members = $("#workgroupMembersList ul li").length;
    var workgroupTitle = $("#workgroup h1").text();

    if ((members > 10) && (workgroupTitle != 'Deelnemers DU Toolbox')) {

        element.parent().append("<span id='toggleMemberlist' class='btn'><a>Toon alle leden</a></span>");
        element.attr("class", "memberList");

        $("#toggleMemberlist").on("click", function () {
            $(members).show();
            $(element).toggleClass("moreMembers");

            if ($("#workgroupMembersList").hasClass("moreMembers")) {
                $("#toggleMemberlist a").text("Toon minder leden");
            }
            else {
                $("#toggleMemberlist a").text("Toon alle leden");
            }
        })
    }
    if (workgroupTitle === 'Deelnemers DU Toolbox') {
        element.css("height", "100%");
    }
};
